@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --mouse-x: 0px;
  --mouse-y: 0px;
}

.h-screen-excluding-topbar {
  height: calc(100vh - 50px);
}

.min-h-screen-excluding-topbar {
  min-height: calc(100vh - 50px);
}

body {
  @apply bg-zinc-300 dark:bg-zinc-900;
}

.payouts-grid {
  display: grid;
  grid-template-rows: 36px max-content 1fr;
}

.card::before {
  content: "";
  background: radial-gradient(
    75vw circle at var(--mouse-x) var(--mouse-y),
    rgba(0, 0, 0, 0.05),
    transparent 40%
  );
}

.dark .card::before {
  background: radial-gradient(
    75vw circle at var(--mouse-x) var(--mouse-y),
    rgba(255, 255, 255, 0.05),
    transparent 40%
  );

}

.svg-container {
  width: 100% !important;
}

.react-datepicker-wrapper input {
  /* color: rgb(161, 161, 170); */
  background-color: rgb(63, 63, 70);
  border-radius: 5px;
  padding: 1px 12px;
  max-width: 7em;
  outline: none;
  margin: 0px;
  cursor: pointer;
}

/* div.react-datepicker__tab-loop {
  margin-left: 0px !important;
} */
